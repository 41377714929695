































































































import { defineComponent, ref, reactive, toRefs, computed, unref } from '@vue/composition-api';
import DomainFormMeta, { useMetaForm } from '@/views/app/domains/DomainFormMeta.vue';
import DomainFormAccess, { useAccessForm } from '@/views/app/domains/DomainFormAccess.vue';
import DomainFormProvisioning, {
  useProvisioningForm,
  DomainProvisioningInput
} from '@/views/app/domains/DomainFormProvisioning.vue';
import Vue from 'vue';
import { createToastInterface } from 'vue-toastification';
import {
  DomainModel,
  useDomainApi,
  DomainRequestModel,
  CreateOrUpdateParams,
  DomainMetaInput,
  DomainAccessInput
} from '@/module/api/domain';
import { FormOptions, formatNumber, formatDbDate } from '@/utils';
import JobStatusIcon from '@/components/job-status-icon/JobStatusIcon.vue';
import { UserMessageError } from '@/lib/user-message-error';
import { useAuth, userCanSeeOwnership } from '@/module/auth';

const toast = createToastInterface({ maxToasts: 3 });

const { assertionContext } = useAuth();

const {
  setFormOptions: setMetaFormOptions,
  getFormData: getMetaFormData,
  reset: resetMetaForm
} = useMetaForm();

const {
  setFormOptions: setAccessFormOptions,
  getFormData: getAccessFormData,
  reset: resetAccessForm
} = useAccessForm();

const {
  setFormOptions: setProvisioningFormOptions,
  getFormData: getProvisioningFormData,
  reset: resetProvisioningForm
} = useProvisioningForm();

const {
  createItem,
  updateItem,
  getDomainAccessCredentials,
  emptyDomainModel,
  isLoading: isDomainLoading
} = useDomainApi();

export enum DialogMode {
  VIEW = 'view',
  EDIT = 'edit',
  ADD = 'add'
}

const emptyState = () => {
  return {
    dialog: false,
    title: 'Add Domain',
    mode: 'add',
    domain: emptyDomainModel,
    mostRecentExtractJob: emptyDomainModel.mostRecentExtractJob,
    isLoading: false
  };
};

const state = reactive({ ...emptyState() });
const domainFormMeta = ref<any>();
const domainFormAccess = ref<any>();
const domainFormProvisioning = ref<any>();

const setFormOptions = (formOptions: FormOptions) => {
  setMetaFormOptions(formOptions);
  setAccessFormOptions(formOptions);
  setProvisioningFormOptions(formOptions);
};

const addDomain = async (params: CreateOrUpdateParams) => {
  // Don't include uuid when creating
  // Never include domainName or domainId
  const domainMetaParams = {
    ...params.domainMetaInput,
    uuid: undefined,
    domainName: undefined,
    domainId: undefined
  };

  const createDomainParams: DomainRequestModel = {
    permissions: params.domainAccessInput?.permissions,
    five9AgentUsername: params.domainAccessInput?.five9AgentUsername,
    five9AgentPassword: params.domainAccessInput?.five9AgentPassword,
    provisioning: params.domainProvisioningInput,
    ...domainMetaParams
  };
  try {
    const createDomainResponse = await createItem(createDomainParams);
    if (createDomainResponse instanceof UserMessageError) {
      toast.error(createDomainResponse.message);
      return;
    }
    if (!createDomainResponse) {
      const errMessage = 'Unable to create domain.';
      Vue.$log.error(errMessage);
      toast.error(errMessage);
      return;
    }
    Vue.$log.debug({ createDomainParams, createDomainResponse });
    toast.success('Successfully Saved Domain');
    state.dialog = false;
    return;
  } catch (err) {
    Vue.$log.error(err);
    toast.error('Unable to create domain.');
  }
};

const updateDomain = async (params: CreateOrUpdateParams) => {
  // Never include domainName or domainId
  const domainMetaParams = {
    ...params.domainMetaInput,
    domainName: undefined,
    domainId: undefined
  };
  // Credentials are managed in Credential Update / View form
  const updateDomainParams: Partial<DomainRequestModel> = {
    uuid: params.domainMetaInput?.uuid,
    provisioning: params.domainProvisioningInput,
    ...domainMetaParams
  };
  try {
    const updateDomainResponse = await updateItem(updateDomainParams);
    if (updateDomainResponse instanceof UserMessageError) {
      toast.error(updateDomainResponse.message);
      return;
    }
    Vue.$log.debug({ updateDomainParams, updateDomainResponse });
    toast.success('Successfully Saved Domain');
    state.dialog = false;
    return;
  } catch (err) {
    Vue.$log.error(err);
    toast.error('Unable to update domain.');
  }
};

const createOrUpdate = async (): Promise<void> => {
  state.isLoading = true;
  const domainMetaInput: DomainMetaInput = getMetaFormData();
  const domainAccessInput: DomainAccessInput = getAccessFormData();
  const domainProvisioningInput: DomainProvisioningInput = getProvisioningFormData();
  const createOrUpdateParams = { domainMetaInput, domainAccessInput, domainProvisioningInput };
  if (state.mode === DialogMode.ADD) {
    await addDomain(createOrUpdateParams);
  } else {
    await updateDomain(createOrUpdateParams);
  }
  state.isLoading = false;
};

const resetForms = async (): Promise<void> => {
  resetMetaForm();
  resetAccessForm();
  resetProvisioningForm();
};

const reset = async (): Promise<void> => {
  state.isLoading = true;
  await resetForms();
  Object.assign(state, emptyState());
  state.isLoading = false;
};

const show = () => {
  state.dialog = true;
};

const edit = (item: DomainModel) => {
  state.isLoading = true;
  state.dialog = false;
  show();
  state.title = `Edit ${item.label}`;
  state.mode = DialogMode.EDIT;
  const editFormOptions: FormOptions = { readonlyMode: false, editMode: true, item: item };
  setFormOptions(editFormOptions);
  state.isLoading = false;
};

const view = async (item: DomainModel) => {
  state.isLoading = true;
  state.dialog = false;
  show();
  state.title = item.label;
  state.mode = DialogMode.VIEW;
  state.domain = item;
  state.mostRecentExtractJob = item.mostRecentExtractJob;
  const credentials = await getDomainAccessCredentials(item.uuid);
  const viewFormOptions: FormOptions = {
    readonlyMode: true,
    editMode: false,
    item: { ...item, ...credentials }
  };
  setFormOptions(viewFormOptions);
  state.isLoading = false;
};

const add = async (): Promise<void> => {
  state.isLoading = true;
  state.dialog = false;
  await reset();
  show();
  state.mode = DialogMode.ADD;
  const addFormOptions: FormOptions = { readonlyMode: false, editMode: false };
  setFormOptions(addFormOptions);
  state.isLoading = false;
};

export function useDomainDialog() {
  return {
    add,
    show,
    view,
    edit,
    reset
  };
}

export default defineComponent({
  name: 'DomainDialog',
  components: { DomainFormMeta, DomainFormAccess, DomainFormProvisioning, JobStatusIcon },
  setup() {
    return {
      ...toRefs(state),
      reset,
      show,
      add,
      edit,
      view,
      isDomainLoading,
      createOrUpdate,
      DialogMode,
      domainFormMeta,
      domainFormAccess,
      domainFormProvisioning,
      formatNumber,
      formatDbDate,
      canUserSeeOwnership: computed(() => {
        return userCanSeeOwnership(assertionContext.value?.roles);
      })
    };
  }
});
