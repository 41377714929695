import { render, staticRenderFns } from "./DomainFormMeta.vue?vue&type=template&id=186160d6&"
import script from "./DomainFormMeta.vue?vue&type=script&lang=ts&"
export * from "./DomainFormMeta.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VForm,VRow,VSelect,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('186160d6')) {
      api.createRecord('186160d6', component.options)
    } else {
      api.reload('186160d6', component.options)
    }
    module.hot.accept("./DomainFormMeta.vue?vue&type=template&id=186160d6&", function () {
      api.rerender('186160d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/app/domains/DomainFormMeta.vue"
export default component.exports