import { MAX_ITEMS_PER_PAGE } from '@/module/api/common';
import Vue from 'vue';
import { toNum } from './format.utils';

export interface PagedResponse {
  limit: number;
  page: number;
  counter: number;
  [key: string]: any;
}

export interface RequestQueryParams {
  limit?: number;
  page?: number;
  [key: string]: any;
}

export interface PageableTable {
  headers?: any[];
  page: number;
  pageCount: number;
  pageCache: any;
  itemsPerPage: number;
  data: any;
}

export function usePagination(maxResultsPerPage = 10) {
  const areThereMorePages = (response: PagedResponse): boolean => {
    const [limit, count] = [toNum(response.limit), toNum(response.count)];
    const wrongTypes = typeof limit !== 'number' || typeof count !== 'number';
    if (!response || !count || !limit || wrongTypes || count < limit) {
      return false;
    } else {
      return true;
    }
  };

  const getNextPageQueryParams = (requestQueryParams: RequestQueryParams): RequestQueryParams => {
    let [limit, page] = [toNum(requestQueryParams.limit), toNum(requestQueryParams.page)];
    if (!limit) {
      limit = maxResultsPerPage;
    }
    if (!page) {
      page = 1;
    }
    return { limit, page };
  };

  const getNextPageQueryParamsFromResponse = (response: PagedResponse): RequestQueryParams => {
    let [limit, page] = [toNum(response.limit), toNum(response.page)];
    if (!limit) {
      limit = maxResultsPerPage;
    }
    if (!page) {
      page = 1;
    }
    if (!areThereMorePages(response)) {
      // TODO: Do something else here? Return undefined and handle from the caller?
      return { limit, page: undefined };
    }
    return { limit, page };
  };

  const emptyPageableTable = (): PageableTable => {
    return {
      itemsPerPage: maxResultsPerPage || MAX_ITEMS_PER_PAGE,
      page: 1,
      pageCount: 1,
      // Identify each page as a numbered key in the cache
      pageCache: {} as Partial<Record<any, any>>,
      data: []
    };
  };

  const cachePage = (table: PageableTable, data: any) => {
    if (!data || (Array.isArray(data) && data.length < 1)) {
      Vue.$log.debug(`No data to add to page ${table.page}`);
      return;
    }
    if (!table.pageCache?.[table.page]) {
      Vue.$log.debug(`Page ${table.page} isn't in the page cache. Adding data to page cache.`);
      table.pageCache[table.page] = data;
    }
  };

  const applyPageChange = async (table: PageableTable, newPageAction: any) => {
    if (
      table.page < table.pageCount ||
      (table.page == table.pageCount && table.pageCache?.[table.page])
    ) {
      Vue.$log.debug(
        `Current page ${table.page} is less than or equal to page count ${table.pageCount} and we have it in the cache, so we will grab the page from the cache`
      );
      table.data = table.pageCache[table.page];
      return table.pageCache[table.page];
    }
    await newPageAction();
    table.data = table.pageCache[table.page];
    return table.pageCache[table.page];
  };

  const resetPageableTable = (table: PageableTable) => {
    Vue.$log.debug('Resetting Table');
    Object.assign(table, emptyPageableTable());
  };

  return {
    applyPageChange,
    cachePage,
    emptyPageableTable,
    resetPageableTable,
    areThereMorePages,
    getNextPageQueryParams,
    getNextPageQueryParamsFromResponse
  };
}
