<template>
  <v-app>
    <v-container fluid>
      <v-card class="mx-auto" max-width="344" outlined>
        <v-img class="white--text align-start" :src="notFoundImage" alt="404">
          <v-card-title class="text-h4">404</v-card-title>
        </v-img>

        <v-card-text class="text-h6 black white--text text-center">We can't find that.</v-card-text>
        <v-card-text class="text-xs text-overline text-right"
          ><a class="text-decoration-none" :href="retryUrl">Go Home →</a></v-card-text
        >
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

const retryUrl = process.env.VUE_APP_PUBLIC_PATH || '/';

export default defineComponent({
  name: 'NotFound',
  setup() {
    const notFoundImage = computed(() => {
      return require('@/assets/images/404.gif');
    });

    return { notFoundImage, retryUrl };
  },
});
</script>
