import { DomainModel } from '@/module/api/domain';

export interface FormOptions {
  readonlyMode?: boolean;
  editMode?: boolean;
  item?: DomainModel;
}

export const emptyFormOptions = () => {
  return {
    readonlyMode: false,
    editMode: false
  };
};
