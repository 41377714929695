var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      model: {
        value: _vm.form.isValid,
        callback: function($$v) {
          _vm.$set(_vm.form, "isValid", $$v)
        },
        expression: "form.isValid"
      }
    },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _vm._v(
              ' VCC User must have Administrator role with "User can use Administrative Services" and "Agent Role" with "User can access Agent application: Web client". '
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "8" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Five9 Agent Username",
                  name: "five9AgentUsername",
                  readonly: _vm.formOptions.readonlyMode,
                  dense: "",
                  outlined: ""
                },
                model: {
                  value: _vm.form.data.five9AgentUsername,
                  callback: function($$v) {
                    _vm.$set(_vm.form.data, "five9AgentUsername", $$v)
                  },
                  expression: "form.data.five9AgentUsername"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { lg: "4" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.domainAccessLevelListSelector,
                  "item-text": "text",
                  "item-value": "value",
                  label: "Permissions",
                  name: "permissions",
                  dense: "",
                  readonly: _vm.formOptions.readonlyMode,
                  outlined: ""
                },
                model: {
                  value: _vm.form.data.permissions,
                  callback: function($$v) {
                    _vm.$set(_vm.form.data, "permissions", $$v)
                  },
                  expression: "form.data.permissions"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-text-field", {
        attrs: {
          label: "Five9 Agent Password",
          name: "five9AgentPassword",
          "append-icon": _vm.seePassword ? "mdi-eye" : "mdi-eye-off",
          type: _vm.seePassword ? "text" : "password",
          dense: "",
          readonly: _vm.formOptions.readonlyMode,
          outlined: ""
        },
        on: {
          "click:append": function($event) {
            _vm.seePassword = !_vm.seePassword
          }
        },
        model: {
          value: _vm.form.data.five9AgentPassword,
          callback: function($$v) {
            _vm.$set(_vm.form.data, "five9AgentPassword", $$v)
          },
          expression: "form.data.five9AgentPassword"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }