var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.newUser
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { justify: "space-around" } },
                [
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-center flex-column",
                      attrs: { cols: "10" }
                    },
                    [
                      _c("ref-title", [_vm._v("Hi there.")]),
                      _c("ref-subtitle", [
                        _vm._v("Welcome to DAMM! I see you are new here."),
                        _c("br"),
                        _vm._v("Let's get you started.")
                      ]),
                      _c("pre", [
                        _vm._v(_vm._s(JSON.stringify(_vm.completedSteps)))
                      ]),
                      _c("pre", [_vm._v(_vm._s(JSON.stringify(_vm.todo)))]),
                      _vm.completedSteps.length === 0
                        ? _c(
                            "div",
                            { staticClass: "step" },
                            [
                              _c(
                                "v-row",
                                { attrs: { justify: "space-around" } },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-center flex-column",
                                      attrs: { cols: "4" }
                                    },
                                    [
                                      _c("div", { staticClass: "question" }, [
                                        _vm._v("I need to:")
                                      ]),
                                      _c("v-checkbox", {
                                        attrs: {
                                          label: "Export Data from a Domain",
                                          value: "export"
                                        },
                                        model: {
                                          value: _vm.todo,
                                          callback: function($$v) {
                                            _vm.todo = $$v
                                          },
                                          expression: "todo"
                                        }
                                      }),
                                      _c("v-checkbox", {
                                        attrs: {
                                          label: "Import Data to a Domain",
                                          value: "import"
                                        },
                                        model: {
                                          value: _vm.todo,
                                          callback: function($$v) {
                                            _vm.todo = $$v
                                          },
                                          expression: "todo"
                                        }
                                      }),
                                      _vm.todo.length > 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                "x-large": "",
                                                color: "success",
                                                dark: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.completeStep(1)
                                                }
                                              }
                                            },
                                            [_vm._v("Continue")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c("v-spacer")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.completedSteps.length === 1
                        ? _c(
                            "div",
                            { staticClass: "step" },
                            [
                              _c("div", { staticClass: "question" }, [
                                _vm._v(
                                  " To export a domain, we need to have admin credentials for the domain, preferably the partition adminstrator. "
                                )
                              ]),
                              _c(
                                "v-form",
                                {
                                  ref: "source-credentials-form",
                                  model: {
                                    value: _vm.sourceForm._valid,
                                    callback: function($$v) {
                                      _vm.$set(_vm.sourceForm, "_valid", $$v)
                                    },
                                    expression: "sourceForm._valid"
                                  }
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "text-center",
                                    attrs: {
                                      label: "Username",
                                      outlined: "",
                                      placeholder:
                                        "Enter an admin username for the source domain"
                                    },
                                    model: {
                                      value: _vm.sourceForm.username,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.sourceForm,
                                          "username",
                                          $$v
                                        )
                                      },
                                      expression: "sourceForm.username"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    staticClass: "text-center",
                                    attrs: {
                                      label: "Password",
                                      outlined: "",
                                      placeholder:
                                        "Enter an admin password for the source domain",
                                      type: "password"
                                    },
                                    model: {
                                      value: _vm.sourceForm.password,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.sourceForm,
                                          "password",
                                          $$v
                                        )
                                      },
                                      expression: "sourceForm.password"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    staticClass: "text-center",
                                    attrs: {
                                      label: "Verify Password",
                                      outlined: "",
                                      placeholder:
                                        "Verify domain admin password for the source domain",
                                      type: "password"
                                    },
                                    model: {
                                      value: _vm.sourceForm.passwordVerify,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.sourceForm,
                                          "passwordVerify",
                                          $$v
                                        )
                                      },
                                      expression: "sourceForm.passwordVerify"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.domainAdminPasswordVerify
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        "x-large": "",
                                        color: "success",
                                        dark: ""
                                      }
                                    },
                                    [_vm._v(" Save Admin Username ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }