export enum DateTimeMethod {
  DATE = 'date',
  TIME = 'time',
  DATE_TIME = 'datetime'
}

export const formatDbDate = (
  dateTime: string | undefined,
  options?: { format: DateTimeMethod }
) => {
  if (!dateTime) {
    return '';
  }
  const date = new Date(dateTime);
  if (!options || options.format === DateTimeMethod.DATE_TIME) {
    return date.toLocaleString('en-US');
  }
  if (options.format === DateTimeMethod.DATE) {
    return date.toDateString();
  }
  if (options.format === DateTimeMethod.TIME) {
    return date.toTimeString();
  }
};

export const formatDbDateTime = (dateTime: string | undefined) => {
  if (!dateTime) {
    return '';
  }
  const date = new Date(dateTime);
  return date.toLocaleTimeString('en-US');
};

export const formatNumber = (num: number): string => {
  return num.toLocaleString('en-us');
};

export const toNum = (numish: any): number => {
  if (!numish) {
    return 0;
  }
  if (typeof numish === 'number') {
    return numish;
  }
  if (isNaN(numish)) {
    return 0;
  }
  return parseInt(numish);
};

export const truncateUrl = (url: string, desiredLength: number) => {
  if (typeof url !== 'string') {
    throw new TypeError('Expected input to be a string');
  }

  if (typeof desiredLength !== 'number') {
    throw new TypeError('Expected length to be a number');
  }

  if (url.length <= desiredLength) {
    return url;
  }

  try {
    const urlShortened = url.substring(0, desiredLength);
    if (urlShortened.length < url.length) {
      return `${urlShortened}...`;
    } else {
      return urlShortened;
    }
  } catch (err) {
    return url;
  }
};

export const isProduction =
  process.env.VUE_APP_BUILD_ENV === 'production' || process.env.NODE_ENV === 'production';
