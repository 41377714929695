export interface CreateFileDownloadElementParams {
  document: Document;
  blobData: any;
  fileName: string;
  contentType?: string;
  blobType?: string;
}

export function useFileUtils() {
  const createFileDownloadElement = (params: CreateFileDownloadElementParams) => {
    const blobType = params?.blobType ? { type: params.blobType } : undefined;
    const fileUrl = window.URL.createObjectURL(new Blob([params?.blobData], blobType));
    const link = params.document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', params?.fileName);
    document.body.appendChild(link);
    return link;
  };

  const createFileDownloadElementAlt = (params: CreateFileDownloadElementParams) => {
    const link = params.document.createElement('a');
    const contentType = params?.contentType ? params.contentType : 'application/octet-stream';
    link.setAttribute(
      'href',
      `data:${contentType};charset=utf-8,` + encodeURIComponent(params.blobData)
    );
    link.setAttribute('download', params?.fileName);
    document.body.appendChild(link);
    return link;
  };
  return {
    createFileDownloadElement,
    createFileDownloadElementAlt
  };
}
