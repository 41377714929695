export enum FilterOperation {
  eq = 'eq',
  gt = 'gt',
  gte = 'gte',
  in = 'in',
  lt = 'lt',
  lte = 'lte',
  ne = 'ne',
  nin = 'nin'
}

export interface ItemToFilter {
  key: string;
  value: any;
}

export interface AppliableFilter extends ItemToFilter {
  op?: FilterOperation;
}

import Vue from 'vue';
import { unref } from '@vue/composition-api';
export function useFilterUtils() {
  const applyEq = (item: any, _filter: AppliableFilter): boolean => {
    const val = item?.[_filter.key];
    if (val == null) {
      return false;
    }
    return val === _filter.value;
  };
  const applyGt = (item: any, _filter: AppliableFilter): boolean => {
    const val = item?.[_filter.key];
    if (val == null || isNaN(val) || isNaN(_filter.value)) {
      return false;
    }
    return val > _filter.value;
  };
  const applyGte = (item: any, _filter: AppliableFilter): boolean => {
    const val = item?.[_filter.key];
    if (val == null || isNaN(val) || isNaN(_filter.value)) {
      return false;
    }
    return val >= _filter.value;
  };

  const applyIn = (item: any, _filter: AppliableFilter): boolean => {
    const val = item?.[_filter.key];
    if (val == null || !Array.isArray(val)) {
      return false;
    }
    return val.includes(_filter.value);
  };

  const applyLt = (item: any, _filter: AppliableFilter): boolean => {
    const val = item?.[_filter.key];
    if (val == null || isNaN(val) || isNaN(_filter.value)) {
      return false;
    }
    return val < _filter.value;
  };

  const applyLte = (item: any, _filter: AppliableFilter): boolean => {
    const val = item?.[_filter.key];
    if (val == null || isNaN(val) || isNaN(_filter.value)) {
      return false;
    }
    return val <= _filter.value;
  };

  const applyNe = (item: any, _filter: AppliableFilter): boolean => {
    const val = item?.[_filter.key];
    if (val == null) {
      return false;
    }
    return val !== _filter.value;
  };

  const applyNin = (item: any, _filter: AppliableFilter): boolean => {
    return !applyIn(item, _filter);
  };

  const applyFilter = (item: any, _filter: AppliableFilter) => {
    try {
      if (_filter.op === FilterOperation.eq) {
        return applyEq(item, _filter);
      }
      if (_filter.op === FilterOperation.gt) {
        return applyGt(item, _filter);
      }
      if (_filter.op === FilterOperation.gte) {
        return applyGte(item, _filter);
      }
      if (_filter.op === FilterOperation.in) {
        return applyIn(item, _filter);
      }
      if (_filter.op === FilterOperation.lt) {
        return applyLt(item, _filter);
      }
      if (_filter.op === FilterOperation.lte) {
        return applyLte(item, _filter);
      }
      if (_filter.op === FilterOperation.ne) {
        return applyNe(item, _filter);
      }
      if (_filter.op === FilterOperation.nin) {
        return applyNin(item, _filter);
      }
    } catch (err) {
      Vue.$log.debug("Filter failed, reason: -- applying as if the filter didn't match", err);
      return false;
    }
  };

  const noFiltersToApply = (filters: AppliableFilter[]) => {
    if (Array.isArray(filters) && filters.length < 1) {
      return true;
    }
    return false;
  };
  const compositeFilter = <T>(filterable: T[], filters: AppliableFilter[]): T[] => {
    const filterableValue = unref(filterable);

    if (noFiltersToApply(filters)) {
      return unref(filterable);
    }

    return filterableValue.filter((item: T) => {
      return filters.every((filterConfig: AppliableFilter) => {
        if (filterConfig.op == null) {
          filterConfig.op = FilterOperation.eq;
        }
        return applyFilter(item, filterConfig);
      });
    });
  };

  return {
    compositeFilter
  };
}
