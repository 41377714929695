


































































































import Vue from 'vue';
import { defineComponent, reactive, toRefs, computed, onMounted } from '@vue/composition-api';
import { usePublicAuthBridge, AuthBridgeActionName } from '@/module/public-auth-bridge';
import { createToastInterface } from 'vue-toastification';
import { useFileUtils, isProduction } from '@/utils';
import { Five9RegionName, Five9RegionList } from '@/module/api/domain';

export default defineComponent({
  name: 'AuthBridgePage',
  components: {},
  props: [
    'organizationUuid',
    'domoSetLoadJobUuid',
    'publicAuthBridgeUuid',
    'action',
    'defaultRegion'
  ],
  setup(props) {
    const toast = createToastInterface({ maxToasts: 3 });
    const { validateSecureCredentialExportBlob, informAppBridgeWasOpened } = usePublicAuthBridge();
    const { createFileDownloadElement } = useFileUtils();
    const emptyState = reactive({
      dialog: false,
      seePassword: false,
      isAuthenticated: false,
      username: '',
      password: '',
      region: Five9RegionName.DEFAULT,
      isLoading: false,
      errorMessage: '',
      blobData: '',
      fileName: ''
    });

    const state = reactive({ ...emptyState });
    const regionIsValid = () => {
      return (
        props.defaultRegion != null &&
        typeof props.defaultRegion === 'string' &&
        props.defaultRegion.toUpperCase() in Five9RegionName
      );
    };
    onMounted(() => {
      informAppBridgeWasOpened(props.publicAuthBridgeUuid);
      if (regionIsValid()) {
        state.region = props.defaultRegion.toUpperCase() as Five9RegionName;
      }
    });

    const showRefreshButton = !isProduction;

    const reset = async () => {
      Object.assign(state, emptyState);
      state.dialog = false;
    };

    const formIsValid = () => state.username !== '' && state.password !== '';

    const rules = {
      required: [(v: string) => !!v || 'Required.']
    };

    const downloadBlobFile = async () => {
      const fileLink = await createFileDownloadElement({
        document,
        blobData: state.blobData,
        blobType: 'application/zip',
        fileName: `secure_credentials_export.zip`
      });
      fileLink.click();
    };

    const attemptSecureCredentialExport = async () => {
      state.isLoading = true;
      try {
        const { data, headers, status } = await validateSecureCredentialExportBlob({
          username: state.username,
          password: state.password,
          region: state.region,
          publicAuthBridgeUuid: props.publicAuthBridgeUuid,
          domoSetLoadJobUuid: props.domoSetLoadJobUuid
        });
        if (status === 203) {
          const errMsg = 'Invalid Login.';
          Vue.$log.debug(errMsg);
          toast.error(errMsg);
          return;
        }
        state.isAuthenticated = true;
        Vue.$log.debug(headers);
        state.blobData = data;
        toast.success(
          'Successfully authenticated. You may now download your secured file. You will need an access code to open the file.'
        );
      } catch (err) {
        if (err instanceof Error) {
          state.errorMessage = err.message;
        } else {
          state.errorMessage = 'Unkown error';
        }
        Vue.$log.error(err);
      } finally {
        state.isLoading = false;
      }
    };

    const handleAuthBridgeAction = async () => {
      state.isLoading = true;
      if (!formIsValid()) {
        toast.error('Please make sure you have entered a username and password.');
        state.isLoading = false;
        return;
      }
      try {
        if (props.action === AuthBridgeActionName.SECURE_CREDENTIAL_ACCESS) {
          await attemptSecureCredentialExport();
        } else {
          toast.error('Unkown error on form submission.');
        }
      } catch (err) {
        Vue.$log.error(err);
        toast.error('Unkown error on form submission.');
      } finally {
        state.isLoading = false;
      }
    };

    const style = 'background-color: #EBEBED';
    const actionText = computed(() => {
      if (props?.action === 'secure-credential-access') {
        return 'You will be able to retrieve a secured user credentials export after your account and access information has been validated.';
      }
      return '';
    });

    return {
      ...toRefs(state),
      actionText,
      rules,
      style,
      handleAuthBridgeAction,
      downloadBlobFile,
      reset,
      Five9RegionList,
      showRefreshButton
    };
  }
});
