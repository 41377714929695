









import { defineComponent /*, ref */ } from '@vue/composition-api';
import { useGeneral } from '@/module/general';

export default defineComponent({
  name: 'ComingSoon',
  setup() {
    const { appName } = useGeneral();

    return {
      appName
    };
  }
});
