var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      model: {
        value: _vm.form.isValid,
        callback: function($$v) {
          _vm.$set(_vm.form, "isValid", $$v)
        },
        expression: "form.isValid"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: {
                  label: "Inbound Campaigns",
                  type: "number",
                  required: "",
                  readonly: _vm.formOptions.readonlyMode,
                  rules: _vm.rules.number,
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.form.data.inboundCampaigns,
                  callback: function($$v) {
                    _vm.$set(_vm.form.data, "inboundCampaigns", $$v)
                  },
                  expression: "form.data.inboundCampaigns"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: {
                  label: "Inbound Voice Lines",
                  type: "number",
                  required: "",
                  rules: _vm.rules.number,
                  readonly: _vm.formOptions.readonlyMode,
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.form.data.inboundVoiceLines,
                  callback: function($$v) {
                    _vm.$set(_vm.form.data, "inboundVoiceLines", $$v)
                  },
                  expression: "form.data.inboundVoiceLines"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: {
                  label: "Total Skill Groups",
                  type: "number",
                  required: "",
                  rules: _vm.rules.number,
                  readonly: _vm.formOptions.readonlyMode,
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.form.data.skillGroups,
                  callback: function($$v) {
                    _vm.$set(_vm.form.data, "skillGroups", $$v)
                  },
                  expression: "form.data.skillGroups"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: {
                  label: "Outbound Campaigns",
                  type: "number",
                  required: "",
                  rules: _vm.rules.number,
                  readonly: _vm.formOptions.readonlyMode,
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.form.data.outboundCampaigns,
                  callback: function($$v) {
                    _vm.$set(_vm.form.data, "outboundCampaigns", $$v)
                  },
                  expression: "form.data.outboundCampaigns"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: {
                  label: "Outbound Voice Lines",
                  type: "number",
                  required: "",
                  rules: _vm.rules.number,
                  readonly: _vm.formOptions.readonlyMode,
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.form.data.outboundVoiceLines,
                  callback: function($$v) {
                    _vm.$set(_vm.form.data, "outboundVoiceLines", $$v)
                  },
                  expression: "form.data.outboundVoiceLines"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: {
                  label: "Total Named Agents",
                  type: "number",
                  required: "",
                  rules: _vm.rules.number,
                  readonly: _vm.formOptions.readonlyMode,
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.form.data.namedAgents,
                  callback: function($$v) {
                    _vm.$set(_vm.form.data, "namedAgents", $$v)
                  },
                  expression: "form.data.namedAgents"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: {
                  label: "Total Call Variables",
                  type: "number",
                  required: "",
                  rules: _vm.rules.number,
                  readonly: _vm.formOptions.readonlyMode,
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.form.data.callVariables,
                  callback: function($$v) {
                    _vm.$set(_vm.form.data, "callVariables", $$v)
                  },
                  expression: "form.data.callVariables"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: {
                  label: "Total CRM Variables",
                  type: "number",
                  required: "",
                  rules: _vm.rules.number,
                  readonly: _vm.formOptions.readonlyMode,
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.form.data.crmVariables,
                  callback: function($$v) {
                    _vm.$set(_vm.form.data, "crmVariables", $$v)
                  },
                  expression: "form.data.crmVariables"
                }
              })
            ],
            1
          ),
          _c("v-col")
        ],
        1
      ),
      _vm.mode === "add"
        ? _c("v-row", [
            _c("p", [
              _vm._v(
                "More provisioning information will be available once the domain is added."
              )
            ])
          ])
        : _c("v-row", [
            _c("p", [
              _vm._v(
                " These values are read-only and reflect data from the most recent snapshot of the domain. "
              )
            ])
          ]),
      _vm.mode !== "add"
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-checkbox", {
                    attrs: {
                      label: "E164 Enabled",
                      type: "boolean",
                      required: "",
                      readonly: "",
                      disabled: "",
                      outlined: "",
                      dense: ""
                    },
                    model: {
                      value:
                        _vm.provisioningInformation.isInternationalNumberFormat,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.provisioningInformation,
                          "isInternationalNumberFormat",
                          $$v
                        )
                      },
                      expression:
                        "provisioningInformation.isInternationalNumberFormat"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-checkbox", {
                    attrs: {
                      label: "TCPA Enabled",
                      type: "boolean",
                      required: "",
                      readonly: "",
                      disabled: "",
                      outlined: "",
                      dense: ""
                    },
                    model: {
                      value: _vm.provisioningInformation.tcpaModeEnabled,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.provisioningInformation,
                          "tcpaModeEnabled",
                          $$v
                        )
                      },
                      expression: "provisioningInformation.tcpaModeEnabled"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-checkbox", {
                    attrs: {
                      label: "Visual IVR Enabled",
                      type: "boolean",
                      required: "",
                      readonly: "",
                      disabled: "",
                      outlined: "",
                      dense: ""
                    },
                    model: {
                      value: _vm.provisioningInformation.visualIvrEnabled,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.provisioningInformation,
                          "visualIvrEnabled",
                          $$v
                        )
                      },
                      expression: "provisioningInformation.visualIvrEnabled"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !(_vm.mode === "add")
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-checkbox", {
                    attrs: {
                      label: "Social Enabled",
                      type: "boolean",
                      required: "",
                      readonly: "",
                      disabled: "",
                      outlined: "",
                      dense: ""
                    },
                    model: {
                      value: _vm.provisioningInformation.soCoCareSocialEnabled,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.provisioningInformation,
                          "soCoCareSocialEnabled",
                          $$v
                        )
                      },
                      expression:
                        "provisioningInformation.soCoCareSocialEnabled"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-checkbox", {
                    attrs: {
                      label: "Chat Enabled",
                      type: "boolean",
                      required: "",
                      readonly: "",
                      disabled: "",
                      outlined: "",
                      dense: ""
                    },
                    model: {
                      value: _vm.provisioningInformation.soCoCareChatsEnabled,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.provisioningInformation,
                          "soCoCareChatsEnabled",
                          $$v
                        )
                      },
                      expression: "provisioningInformation.soCoCareChatsEnabled"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-checkbox", {
                    attrs: {
                      label: "Email Enabled",
                      type: "boolean",
                      required: "",
                      readonly: "",
                      disabled: "",
                      outlined: "",
                      dense: ""
                    },
                    model: {
                      value: _vm.provisioningInformation.soCoCareEmailsEnabled,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.provisioningInformation,
                          "soCoCareEmailsEnabled",
                          $$v
                        )
                      },
                      expression:
                        "provisioningInformation.soCoCareEmailsEnabled"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-checkbox", {
                attrs: {
                  label: "Connectors Enabled",
                  type: "boolean",
                  required: "",
                  readonly: "",
                  disabled: "",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.provisioningInformation.connectorsEnabled,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.provisioningInformation,
                      "connectorsEnabled",
                      $$v
                    )
                  },
                  expression: "provisioningInformation.connectorsEnabled"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("v-checkbox", {
                attrs: {
                  label: "Advanced Connectors Enabled",
                  type: "boolean",
                  required: "",
                  readonly: "",
                  disabled: "",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.provisioningInformation.advancedConnectorsEnabled,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.provisioningInformation,
                      "advancedConnectorsEnabled",
                      $$v
                    )
                  },
                  expression:
                    "provisioningInformation.advancedConnectorsEnabled"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("v-checkbox", {
                attrs: {
                  label: "External Routing Enabled",
                  type: "boolean",
                  required: "",
                  readonly: "",
                  disabled: "",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.provisioningInformation.externalRoutingEnabled,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.provisioningInformation,
                      "externalRoutingEnabled",
                      $$v
                    )
                  },
                  expression: "provisioningInformation.externalRoutingEnabled"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }