var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      model: {
        value: _vm.form.isValid,
        callback: function($$v) {
          _vm.$set(_vm.form, "isValid", $$v)
        },
        expression: "form.isValid"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-select", {
                staticClass: "mt-4",
                attrs: {
                  items: _vm.five9RegionList,
                  label: "Region",
                  name: "region",
                  readonly:
                    _vm.formOptions.readonlyMode || _vm.formOptions.editMode,
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.form.data.region,
                  callback: function($$v) {
                    _vm.$set(_vm.form.data, "region", $$v)
                  },
                  expression: "form.data.region"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("v-select", {
                staticClass: "mt-4",
                attrs: {
                  items: _vm.five9SectorList,
                  label: "Sector",
                  name: "sector",
                  readonly: _vm.formOptions.readonlyMode,
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.form.data.sector,
                  callback: function($$v) {
                    _vm.$set(_vm.form.data, "sector", $$v)
                  },
                  expression: "form.data.sector"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.formOptions.readonlyMode || _vm.formOptions.editMode
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Domain Name",
                      name: "domainName",
                      readonly: "",
                      outlined: "",
                      dense: ""
                    },
                    model: {
                      value: _vm.form.data.domainName,
                      callback: function($$v) {
                        _vm.$set(_vm.form.data, "domainName", $$v)
                      },
                      expression: "form.data.domainName"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Domain ID",
                      name: "domainId",
                      readonly: "",
                      outlined: "",
                      dense: ""
                    },
                    model: {
                      value: _vm.form.data.domainId,
                      callback: function($$v) {
                        _vm.$set(_vm.form.data, "domainId", $$v)
                      },
                      expression: "form.data.domainId"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: {
                  label: "Label",
                  autofocus: "",
                  required: "",
                  name: "label",
                  readonly: _vm.formOptions.readonlyMode,
                  "persistent-hint": "",
                  hint: _vm.getAutoFillHint(),
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.form.data.label,
                  callback: function($$v) {
                    _vm.$set(_vm.form.data, "label", $$v)
                  },
                  expression: "form.data.label"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }