export default [
  {
    path: 'users',
    name: 'UsersList',
    component: () => import(/* webpackChunkName: "users" */ '@/views/admin/users/UserList.vue')
  },
  {
    path: 'organizations',
    name: 'OrganizationList',
    component: () =>
      import(
        /* webpackChunkName: "organizations" */ '@/views/admin/organizations/OrganizationList.vue'
      )
  }
  // {
  //   path: 'docs',
  //   name: 'DocsList',
  //   component: () =>
  //     import(/* webpackChunkName: "organizations" */ '@/views/admin/docs/DocsList.vue')
  // }
  // {
  //   path: 'organizations',
  //   name: 'OrganizationsList',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "organizations" */ '@/views/admin/organizations/OrganizationsList.vue'
  //     )
  // }
];
