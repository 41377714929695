var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialog
    ? _c(
        "v-dialog",
        {
          attrs: { "retain-focus": false, width: "45vw", scrollable: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "no-x", attrs: { loading: _vm.isLoading } },
            [
              _c("v-card-title", [
                _c("span", { staticClass: "text-h5" }, [
                  _vm._v(_vm._s(_vm.title) + " ")
                ])
              ]),
              _vm.mode === _vm.DialogMode.VIEW &&
              _vm.mostRecentExtractJob &&
              _vm.mostRecentExtractJob.objectSummary &&
              _vm.mostRecentExtractJob.objectSummary.total
                ? _c(
                    "v-card-subtitle",
                    {
                      staticClass: "mt-1",
                      attrs: { title: _vm.mostRecentExtractJob.uuid }
                    },
                    [
                      _vm._v("Last Snapshot: "),
                      _c("job-status-icon", {
                        attrs: {
                          job: _vm.mostRecentExtractJob,
                          suffix:
                            _vm.formatNumber(
                              _vm.mostRecentExtractJob.objectSummary.total
                            ) + " objects at "
                        }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatDbDate(_vm.mostRecentExtractJob.updatedAt)
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-card-text",
                { staticClass: "no-x", staticStyle: { height: "40vh" } },
                [
                  _vm.mode === _vm.DialogMode.VIEW && _vm.canUserSeeOwnership
                    ? _c(
                        "v-row",
                        { staticClass: "mt-1" },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "User ID",
                                  name: "userId",
                                  readonly: "",
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.domain.userId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.domain, "userId", $$v)
                                  },
                                  expression: "domain.userId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Organization ID",
                                  name: "organizationId",
                                  readonly: "",
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.domain.organizationId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.domain, "organizationId", $$v)
                                  },
                                  expression: "domain.organizationId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("domain-form-meta", { ref: "domainFormMeta" }),
                  _vm.mode !== _vm.DialogMode.EDIT
                    ? _c(
                        "v-card",
                        [
                          _c("v-card-title", [_vm._v("Credentials")]),
                          _c(
                            "v-card-text",
                            [
                              _c("domain-form-access", {
                                ref: "domainFormAccess"
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-expansion-panels",
                    { attrs: { accordion: "" } },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _vm._v("Provisioning")
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("domain-form-provisioning", {
                                ref: "domainFormProvisioning",
                                attrs: { mode: _vm.mode }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm.mode === _vm.DialogMode.VIEW
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [_vm._v(" Close ")]
                      )
                    : _vm._e(),
                  _vm.mode !== _vm.DialogMode.VIEW
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "error", text: "" },
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    : _vm._e(),
                  _vm.mode !== _vm.DialogMode.VIEW
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", disabled: _vm.isLoading },
                          on: { click: _vm.createOrUpdate }
                        },
                        [_vm._v(" Save ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }