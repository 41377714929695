<template>
  <v-app>
    <v-container fluid>
      <v-card class="mx-auto" max-width="344" outlined>
        <v-img class="white--text align-start" :src="maintenanceImage" alt="503">
          <v-card-title class="text-h4">503</v-card-title>
        </v-img>

        <v-card-text class="text-h6 black white--text text-center">Temporarily Unavailable</v-card-text>
        <v-card-text class="text-xs text-overline text-right">
          <p>Please check back again soon.</p>
          <a class="text-decoration-none" :href="retryUrl">Try Again →</a></v-card-text
        >
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

const retryUrl = process.env.VUE_APP_PUBLIC_PATH || '/';

export default defineComponent({
  name: 'MaintenanceMode',
  setup() {
    const maintenanceImage = computed(() => {
      return require('@/assets/images/maintenance.gif');
    });

    return {
      maintenanceImage,
      retryUrl,
    };
  },
});
</script>
