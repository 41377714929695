export default [
  { heading: 'ADMIN' },
  {
    title: 'Users',
    icon: 'mdi-account',
    route: { name: 'UsersList' }
  },
  {
    title: 'Organizations',
    icon: 'mdi-domain',
    route: { name: 'OrganizationList' }
  },
  // { title: 'Organizations', icon: 'mdi-domain', route: { name: 'OrganizationsList' } },
  // { title: 'Manage Docs', icon: 'mdi-account-box-multiple', route: { name: 'DocsList' } },
  { divider: true }
];
