export default [
  {
    path: 'domain',
    name: 'Domain',
    component: () =>
      import(/* webpackChunkName: "domain" */ '@/views/app/domains/DomainLayout.vue'),
    children: [
      {
        path: '/domain/home',
        name: 'DomainList',
        component: () =>
          import(/* webpackChunkName: "domainPage" */ '@/views/app/domains/DomainList.vue')
      },
      {
        path: '/domain/:uuid/workspace',
        name: 'DomainWorkspace',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "domainWorkspace" */ '@/views/app/domains/DomainWorkspace.vue'
          )
      },
      {
        path: '/domain/:uuid/job/export/:jobUuid',
        name: 'WorkspaceExportJobDetail',
        props: true,
        component: () =>
          import(/* webpackChunkName: "exportJobDetail" */ '@/views/app/export/ExportJobDetail.vue')
      },
      {
        path: '/domain/:uuid/job/export/:jobUuid/browse',
        name: 'DomainObjectBrowser',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "domainObjectBrowser" */ '@/views/app/domains/DomainObjectBrowser.vue'
          )
      },
      {
        path: '/domain/:uuid/job/import/wizard/setup',
        name: 'ImportJobImportSetupWizard',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "importSetupWizard" */ '@/views/app/import/ImportJobImportSetupWizard.vue'
          )
      },
      {
        path: '/domain/:uuid/job/reset/wizard/setup',
        name: 'ImportJobResetSetupWizard',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "resetSetupWizard" */ '@/views/app/import/ImportJobResetSetupWizard.vue'
          )
      },
      {
        path: '/domain/:uuid/job/restore/wizard/setup',
        name: 'ImportJobRestoreSetupWizard',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "restoreSetupWizard" */ '@/views/app/import/ImportJobRestoreSetupWizard.vue'
          )
      },
      {
        path: '/domain/:uuid/workspace/export-jobs',
        name: 'WorkspaceExportJobList',
        props: true,
        component: () =>
          import(/* webpackChunkName: "exportJobList" */ '@/views/app/export/ExportJobList.vue')
      },
      {
        path: '/domain/:uuid/workspace/load-jobs',
        name: 'WorkspaceImportJobList',
        props: true,
        component: () =>
          import(/* webpackChunkName: "importJobList" */ '@/views/app/import/ImportJobList.vue')
      },
      {
        path: '/domain/:uuid/job/import/:jobUuid',
        name: 'WorkspaceImportJobDetail',
        props: true,
        component: () =>
          import(/* webpackChunkName: "importJobDetail" */ '@/views/app/import/ImportJobDetail.vue')
      },
      {
        path: '/domain/:uuid/job/import/:jobUuid/wizard/run',
        name: 'ImportJobRunWizard',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "importJobRunWizard" */ '@/views/app/import/ImportJobRunWizard.vue'
          )
      },
      {
        path: '/domain/:uuid/job/import/:jobUuid/view',
        name: 'WorkspaceImportJobDetailValidations',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "importJobValidations" */ '@/views/app/import/ImportJobDetailValidations.vue'
          )
      },
      {
        path: '/domain/:uuid/job/import/:jobUuid/fix',
        name: 'WorkspaceImportJobDetailFix',
        props: true,
        component: () =>
          import(/* webpackChunkName: "importJobFix" */ '@/views/app/import/ImportJobDetailFix.vue')
      },
      {
        path: '/domain/:uuid/job/import/:jobUuid/browse',
        name: 'LoadJobOperationBrowser',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "loadJobOperationBrowser" */ '@/views/app/import/LoadJobOperationBrowser.vue'
          )
      },
      {
        path: '/domain/:uuid/job/import/:jobUuid/transform',
        name: 'TransformJobBrowser',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "transformJobBrowser" */ '@/views/app/import/TransformJobBrowser.vue'
          )
      }
      // {
      //   path: '/domain/:uuid/workspace/export-jobs',
      //   name: 'DomainWorkspaceExports',
      //   props: true,
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "domainWorkspaceExports" */ '@/views/app/DomainWorkspaceExports.vue'
      //     )
      // }
    ]
  }
];
