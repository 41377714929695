import '@/lib/log';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
Vue.use(VueRouter);

// import { AuthRole } from '@/module/auth/auth.interface';

import MaintenanceModeLayout from '@/layouts/MaintenanceModeLayout.vue';
import NotAuthorizedLayout from '@/layouts/NotAuthorizedLayout.vue';
import NotFoundLayout from '@/layouts/NotFoundLayout.vue';

import AppLauncher from '@/components/launcher/AppLauncher.vue';
import AdminLayout from '@/layouts/app/AdminLayout.vue';
import AppLayout from '@/layouts/app/AppLayout.vue';
import AuthBridgeLayout from '@/layouts/app/AuthBridgeLayout.vue';
import SplashLayout from '@/layouts/app/SplashLayout.vue';
import SystemLayout from '@/layouts/app/SystemLayout.vue';
import Welcome from '@/views/app/Welcome.vue';

import { useAuth } from '@/module/auth';
// import { SysConfigModel, useGeneral } from '@/module/general';

// Admin
import AdminRoutes from '@/views/admin/routes';

// System - feedback, help, etc.
import SystemRoutes from '@/views/system/routes';

// Your app
import AppRoutes from '@/views/app/routes';

// Shared testing
// import { baz } from '@shared/utils';
// console.log('TESTING SHARED STUFF: ', baz('whoopee!'));

import ComingSoon from '@/views/app/ComingSoon.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: {
      name: 'AppHome'
    }
  },
  {
    path: '/welcome',
    props: true,
    component: SplashLayout,
    children: [
      {
        path: '',
        name: 'Welcome',
        component: Welcome
      }
    ]
  },
  {
    path: '/launcher/:portalToken/:appId',
    name: 'Launcher',
    meta: {
      launcher: true
    },
    props: true,
    component: AppLauncher
  },
  {
    path: '/auth-bridge/:publicAuthBridgeUuid/:action/:domoSetLoadJobUuid/:region',
    name: 'AuthBridge',
    props: true,
    component: AuthBridgeLayout
  },
  {
    path: '/dashboard',
    meta: {
      // Non Public. Requires valid Portal Auth Flow (eg. you must have logged into the portal and have been assigned this app.)
      auth: true

      // Uncomment to test your Portal User's appConfig.roles settings
      // Include { AuthRole } from imports above, too
      // authorize: [AuthRole.Admin],
    },
    component: AppLayout,

    children: [
      {
        path: '/coming-soon',
        name: 'ComingSoon',
        component: ComingSoon
      },
      // {
      //   path: '',
      //   name: 'AppHome',
      //   component: AppHome
      // },

      // App Modules
      ...AppRoutes
    ]
  },

  {
    path: '/admin',
    meta: {
      // Non Public. Requires valid Portal Auth Flow (eg. you must have logged into the portal and have been assigned this app.)
      auth: true,
      authorize: true

      // Uncomment to test your Portal User's appConfig.roles settings
      // Include { AuthRole } from imports above, too
      // authorize: [AuthRole.Admin],
    },
    component: AdminLayout,

    children: [
      // App Modules
      ...AdminRoutes
    ]
  },
  {
    path: '/system',
    meta: {
      // Non Public. Requires valid Portal Auth Flow (eg. you must have logged into the portal and have been assigned this app.)
      auth: true,
      authorize: true

      // Uncomment to test your Portal User's appConfig.roles settings
      // Include { AuthRole } from imports above, too
      // authorize: [AuthRole.Admin],
    },
    component: SystemLayout,

    children: [
      // System items
      ...SystemRoutes
    ]
  },

  {
    path: '/maintenance',
    name: 'MaintenanceMode',
    component: MaintenanceModeLayout,
    meta: {
      maintenance: true
    }
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFoundLayout
  },
  {
    path: '/403',
    name: 'NotAuthorized',
    component: NotAuthorizedLayout
  },
  // If no other routes match... 404!
  {
    path: '*',
    name: 'NotFound',
    component: NotFoundLayout
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const { isAdmin, isUser, authToken, portalLogin, getState } = useAuth();

router.beforeEach(async (to, from, next) => {
  Vue.$log.debug('router/beforeEach - to/from ', [to, from]);
  const launcher = to.matched.some((r) => r.meta.launcher);
  const auth = to.matched.some((r) => r.meta.auth);
  const authorize = to.matched.some((r) => r.meta.authorize);
  // const { sysConfig, getSysconfig } = useGeneral();

  // Short Circuit anything marked with launcher metea
  if (launcher) {
    // Vue.$log.debug('Launching...');
    return next();
  }

  if (!launcher && authToken.value === '') {
    // Attempt to get the state back
    Vue.$log.debug('Auth token not available. Attempting state reload');
    await getState();
  }

  Vue.$log.debug('router/beforeEach - isAdmin:', isAdmin.value);
  Vue.$log.debug('router/beforeEach - isUser:', isUser.value);
  Vue.$log.debug('router/beforeEach - authToken:', authToken.value);
  // Vue.$log.debug('router/beforeEach - sysConfig:', sysConfig);
  Vue.$log.debug('router/beforeEach - route requires auth: ', auth);
  Vue.$log.debug('router/beforeEach - route requires authorize: ', authorize);

  // let initSysConfig: SysConfigModel;
  // let maintenanceMode: boolean;

  // TODO: Determine why sysConfig import isn't updated in this tick
  //
  // Sysconfig stuff
  // if (isAuthenticated.value && (!sysConfig || Object.keys(sysConfig).length === 0)) {
  //   Vue.$log.debug('sysConfig empty. Trying to load...');
  //   try {
  //     initSysConfig = await getSysconfig();
  //     Vue.$log.debug('router/beforeEach - Using fresh sysconfig:', initSysConfig);
  //     //@ts-ignore
  //     maintenanceMode = initSysConfig['MAINTENANCE_MODE']
  //       ? (initSysConfig['MAINTENANCE_MODE'] as boolean)
  //       : false;
  //   } catch (err) {
  //     maintenanceMode = false;
  //     Vue.$log.error('router/beforeEach - Unable to load sysConfig', err);
  //   }
  // } else {
  //   // @ts-ignore
  //   maintenanceMode = sysConfig['MAINTENANCE_MODE']
  //     ? (sysConfig['MAINTENANCE_MODE'].value as boolean)
  //     : false;
  // }

  // Vue.$log.debug('route/beforeEach - maintenance mode status: ', maintenanceMode);

  // Maintenance Check
  // if (isUser.value) {
  //   if (maintenanceMode && !isAdmin.value && !to.matched.some((r) => r.meta.maintenance)) {
  //     router.push({ name: 'MaintenanceMode' });
  //   } else {
  //     Vue.$log.debug('router/beforeEach - Admin user detected. Skipping maintenance re-route.');
  //   }
  // }

  // Check protections
  if (auth || authorize) {
    Vue.$log.debug('router/beforeEach - Route check. Requires admin or authorize meta: ', [
      auth,
      authorize
    ]);

    // Does it require authorization (in this case, just admin)
    if (auth && authorize) {
      Vue.$log.debug(
        'router/beforeEach - Route check - auth && authorize needed (auth/authorize): ',
        [auth, authorize]
      );
      // Is this a real user and do they have admin?
      if (isAdmin) {
        Vue.$log.debug(
          'router/beforeEach - Route check. Requires admin & auth (isAdmin):',
          isAdmin
        );
        // YOU SHALL PASS
        return next();
      } else {
        Vue.$log.debug(
          'router/beforeEach - Not allowed here. Not an admin and authorize w/ admin meta is required'
        );
        return next({ name: 'NotAuthorized' });
      }
    }

    // Does it require just a portal user?
    else if (auth && !authorize) {
      Vue.$log.debug(
        'router/beforeEach - Route check, authorize meta not required, but user must be authenticated (auth, authorize, token): ',
        [auth, authorize, authToken.value]
      );
      if (isUser.value) {
        Vue.$log.debug('router/beforeEach - We are authorized (isUser).', [isUser.value]);
        // YOU SHALL PASS
        return next();
      } else {
        Vue.$log.debug('router/beforeEach - Not authorized. Redirecting.');
        portalLogin();
      }
    }

    // Outta here, poser.
    else {
      Vue.$log.debug('router/beforeEach - YOU SHALL NOT PASS!');
      portalLogin();
    }
  } else {
    Vue.$log.debug('No auth needed!!', [auth, authorize]);
    return next();
  }

  Vue.$log.debug('router/beforeEach - Fall-through... no checks passed. Booting you.');
  portalLogin();
});

export default router;
