import AppHome from './AppHome.vue';
import DomainRoutes from './domains/routes';

export default [
  {
    path: '',
    name: 'AppHome',
    component: AppHome
  },
  ...DomainRoutes
];
