
















































import Vue from 'vue';
import { defineComponent, reactive, toRefs } from '@vue/composition-api';
import {
  DomainAccessLevelName,
  domainAccessLevelListSelector,
  DomainAccessInput
} from '@/module/api/domain';
import { FormOptions, emptyFormOptions } from '@/utils';

const state = reactive({
  seePassword: false
});

const emptyFormData = () => {
  return {
    permissions: DomainAccessLevelName.READWRITE,
    five9AgentUsername: '',
    five9AgentPassword: ''
  };
};

const emptyForm = () => {
  return {
    isValid: false,
    data: emptyFormData()
  };
};

const form = reactive(emptyForm());

const getFormData = (): DomainAccessInput => {
  return {
    permissions: form.data.permissions,
    five9AgentUsername: form.data.five9AgentUsername,
    five9AgentPassword: form.data.five9AgentPassword
  };
};

/**
 * Note: we are doing this because there isn't an easy way I've found to do
 * conditional validation.
 *
 * @returns An empty string if valid, else a string error message.
 */
const validateFormData = (formData: DomainAccessInput): string => {
  if (formData.permissions === DomainAccessLevelName.NONE) {
    if (formData.five9AgentUsername || formData.five9AgentPassword) {
      return 'Username and password must be empty if permissions are NONE.';
    }
  } else if (!formData.five9AgentUsername || !formData.five9AgentPassword) {
    return 'Username and password are required.';
  }
  return '';
};

const reset = () => {
  Object.assign(form, emptyForm());
  state.seePassword = false;
};

const formOptions: FormOptions = reactive({
  ...emptyFormOptions()
});

const rules = {
  required: [(v: string) => !!v || 'Required.']
};

export const setFormOptions = (formOptionsParam: FormOptions) => {
  reset();
  Object.assign(formOptions, formOptionsParam);
  if ((formOptionsParam?.editMode || formOptionsParam?.readonlyMode) && formOptionsParam?.item) {
    Object.assign(form.data, {
      ...formOptionsParam.item
    });
  }
};

export const useAccessForm = () => {
  return {
    getFormData,
    validateFormData,
    reset,
    setFormOptions,
    form
  };
};

export default defineComponent({
  name: 'DomainFormAccess',
  setup() {
    return {
      ...toRefs(state),
      form,
      reset,
      getFormData,
      formOptions,
      rules,
      setFormOptions,
      DomainAccessLevelName,
      domainAccessLevelListSelector: domainAccessLevelListSelector().filter((v) =>
        formOptions.editMode ? true : v.value !== DomainAccessLevelName.NONE
      )
    };
  }
});
