





































































import { defineComponent /* , ref */, reactive, toRefs, computed } from '@vue/composition-api';
import Vue from 'vue';
import {
  five9RegionList,
  five9SectorList,
  Five9SectorName,
  Five9RegionName,
  DomainMetaInput
} from '@/module/api/domain';
import { FormOptions, emptyFormOptions } from '@/utils';

const emptyForm = () => {
  return {
    isValid: false,
    data: {
      sector: Five9SectorName.DEFAULT,
      region: Five9RegionName.DEFAULT,
      domainName: '',
      domainId: '',
      label: '',
      uuid: ''
    }
  };
};
const form = reactive(emptyForm());

const rules = {
  required: [(v: string) => !!v || 'Required.']
};

const getFormData = (): DomainMetaInput => {
  return {
    sector: form.data.sector,
    region: form.data.region,
    domainName: form.data.domainName,
    domainId: form.data.domainId,
    label: form.data.label,
    uuid: form.data?.uuid
  };
};

const getFormValidity = (): boolean => {
  return form.isValid;
};

const reset = () => {
  Object.assign(form, emptyForm());
};

const formOptions: FormOptions = reactive({
  ...emptyFormOptions
});

const setFormOptions = (formOptionsParam: FormOptions) => {
  reset();
  Object.assign(formOptions, formOptionsParam);
  if ((formOptionsParam?.editMode || formOptionsParam?.readonlyMode) && formOptionsParam?.item) {
    Object.assign(form.data, {
      ...formOptionsParam.item
    });
  }
};

const getAutoFillHint = () => {
  return `${
    formOptions.readonlyMode ? '' : "Leave blank to auto-fill with 'Domain Name (Domain ID)'"
  }`;
};

export const useMetaForm = () => {
  return {
    setFormOptions,
    reset,
    getFormData
  };
};

export default defineComponent({
  name: 'DomainFormMeta',
  setup() {
    return {
      form,
      getFormData,
      getAutoFillHint,
      five9RegionList: five9RegionList(),
      five9SectorList: five9SectorList(),
      setFormOptions,
      formOptions,
      getFormValidity,
      rules
    };
  }
});
