






































import { defineComponent, ref } from '@vue/composition-api';
import { useGeneral } from '@/module/general';
import router from '@/router';

export default defineComponent({
  name: 'AppHome',
  setup() {
    const { appName } = useGeneral();
    router.push({ name: 'DomainList' });

    const width = ref(400);

    const increaseWidth = (v = 10) => {
      width.value += v;
    };
    const decreaseWidth = (v = 10) => {
      width.value -= v;
    };

    return {
      appName,
      width,
      increaseWidth,
      decreaseWidth
    };
  }
});
