var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { style: _vm.style, attrs: { "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c("v-spacer"),
          _c(
            "v-col",
            [
              _c(
                "v-card",
                {
                  staticClass: "pb-8 mt-8",
                  attrs: {
                    loading: _vm.isLoading,
                    "max-width": "700",
                    "min-width": "700"
                  }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { align: "center" } },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "mb-0 pb-0" },
                                    [
                                      _c("ref-subtitle", [
                                        _vm._v("Five9 Secure Credential Access")
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "body-1 text-left ml-4"
                                        },
                                        [
                                          _vm._v(
                                            " Please validate your Five9 credentials below. " +
                                              _vm._s(_vm.actionText) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c("v-divider", {
                                        staticClass: "mt-8 mr-8",
                                        attrs: { inset: "" }
                                      }),
                                      _c(
                                        "v-form",
                                        {
                                          staticClass: "mt-6 pt-4",
                                          on: {
                                            submit: function($event) {
                                              $event.preventDefault()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "8" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      outlined: "",
                                                      disabled:
                                                        _vm.isLoading ||
                                                        _vm.isAuthenticated,
                                                      label: "Username",
                                                      "prepend-icon":
                                                        "mdi-account-box",
                                                      autofocus: "",
                                                      tabindex: "1"
                                                    },
                                                    model: {
                                                      value: _vm.username,
                                                      callback: function($$v) {
                                                        _vm.username = $$v
                                                      },
                                                      expression: "username"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "4" } },
                                                [
                                                  _c("v-select", {
                                                    staticClass: "mb-2",
                                                    attrs: {
                                                      outlined: "",
                                                      "hide-details": "",
                                                      placeholder:
                                                        "Domain Location",
                                                      label: "Domain Location",
                                                      rules: _vm.rules.required,
                                                      "prepend-icon":
                                                        "mdi-earth",
                                                      items:
                                                        _vm.Five9RegionList,
                                                      tabindex: "3"
                                                    },
                                                    model: {
                                                      value: _vm.region,
                                                      callback: function($$v) {
                                                        _vm.region = $$v
                                                      },
                                                      expression: "region"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-text-field", {
                                            attrs: {
                                              outlined: "",
                                              disabled:
                                                _vm.isLoading ||
                                                _vm.isAuthenticated,
                                              "append-icon": _vm.seePassword
                                                ? "mdi-eye"
                                                : "mdi-eye-off",
                                              type: _vm.seePassword
                                                ? "text"
                                                : "password",
                                              autocomplete: "new",
                                              label: "Password",
                                              "prepend-icon": "mdi-lock",
                                              tabindex: "2"
                                            },
                                            on: {
                                              "click:append": function($event) {
                                                _vm.seePassword = !_vm.seePassword
                                              }
                                            },
                                            model: {
                                              value: _vm.password,
                                              callback: function($$v) {
                                                _vm.password = $$v
                                              },
                                              expression: "password"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-row",
                                { staticClass: "pb-2" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "ml-6",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      !_vm.isAuthenticated
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                large: "",
                                                color: "secondary",
                                                tabindex: "4"
                                              },
                                              on: {
                                                click:
                                                  _vm.handleAuthBridgeAction
                                              }
                                            },
                                            [_vm._v("Submit")]
                                          )
                                        : _vm._e(),
                                      _vm.isAuthenticated
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                large: "",
                                                color: "success",
                                                tabindex: "5"
                                              },
                                              on: {
                                                click: _vm.downloadBlobFile
                                              }
                                            },
                                            [_vm._v("Download")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      ),
      _c("v-row")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }