import { render, staticRenderFns } from "./NotAuthorized.vue?vue&type=template&id=6b998204&"
import script from "./NotAuthorized.vue?vue&type=script&lang=js&"
export * from "./NotAuthorized.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VApp,VCard,VCardText,VCardTitle,VContainer,VImg})


/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6b998204')) {
      api.createRecord('6b998204', component.options)
    } else {
      api.reload('6b998204', component.options)
    }
    module.hot.accept("./NotAuthorized.vue?vue&type=template&id=6b998204&", function () {
      api.rerender('6b998204', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/NotAuthorized.vue"
export default component.exports