export default [
  // { title: 'Dashboard', icon: 'mdi-home', route: { name: 'AppHome' } },
  // {
  //   title: 'My Domains',
  //   icon: 'mdi-domain',
  //   route: { name: 'MyDomains' }
  // },
  {
    title: 'Domain Workspaces',
    icon: 'mdi-domain',
    route: { name: 'DomainList' }
  },
  { divider: true }
];
