






































































































































































































































import { defineComponent /* , ref */, reactive, ref } from '@vue/composition-api';
import Vue from 'vue';
import {
  emptyFive9ProvisioningValues,
  Five9ProvisioningValues,
  DomainModel,
  useDomainApi
} from '@/module/api/domain';
import { useDomoApi } from '@/module/api/domo';
import { FormOptions, emptyFormOptions, toNum } from '@/utils';

const { selectedItem } = useDomainApi();
const { getItems } = useDomoApi();

export type DomainProvisioningInput = Five9ProvisioningValues;

const emptyForm = () => {
  return {
    isValid: false,
    data: {
      ...emptyFive9ProvisioningValues()
    }
  };
};
const form = reactive(emptyForm());

const provisioningInformation: any = ref({});

const getFormData = (): DomainProvisioningInput => {
  return form.data;
};

const reset = () => {
  Object.assign(form, emptyForm());
};

const rules = {
  required: [(v: string) => !!v || 'Required.'],
  number: [
    (v: any) => /[0-9]/gi.test(v) || 'Only Numbers Allowed.',
    (v: any) => Math.abs(toNum(v) || 0) === toNum(v) || "Value can't be negative"
  ]
};

const formOptions: FormOptions = reactive({
  ...emptyFormOptions
});

const setFormOptions = (formOptionsParam: FormOptions) => {
  reset();
  Object.assign(formOptions, formOptionsParam);
  if ((formOptionsParam?.editMode || formOptionsParam?.readonlyMode) && formOptionsParam?.item) {
    Object.assign(form.data, {
      ...formOptionsParam.item?.provisioning
    });
  }
};

const getSystemsSettingsProvisioning = async () => {
  provisioningInformation.value = await getItems({
    raw: false,
    query: {
      'filter[domoSetUuid]': formOptions.item?.mostRecentExtractJob?.domoSetUuid,
      'filter[entityType]': 'SYSTEM_SETTINGS'
    }
  });
  provisioningInformation.value = provisioningInformation.value[0]?.domb?.features;
};

export const useProvisioningForm = () => {
  return {
    setFormOptions,
    reset,
    getFormData
  };
};

export default defineComponent({
  name: 'DomainFormProvisioning',
  props: ['mode'],
  setup() {
    getSystemsSettingsProvisioning();
    return {
      form,
      reset,
      rules,
      formOptions,
      setFormOptions,
      getFormData,
      selectedItem,
      provisioningInformation
    };
  }
});
