













































































import { defineComponent, reactive, ref, toRefs } from '@vue/composition-api';
import { useGeneral } from '@/module/general';

export default defineComponent({
  name: 'Welcome',
  setup() {
    const { appName } = useGeneral();
    const state = reactive({
      newUser: true,
      domainAdminUsername: '',
      completedSteps: [],
      todo: []
    });
    const sourceForm = reactive({
      _valid: false,
      username: '',
      password: ''
    });

    const completeStep = (step: number) => {
      //@ts-ignore
      state.completedSteps.push(step);
    };

    return {
      ...toRefs(state),
      ...toRefs(sourceForm),
      appName,
      completeStep
    };
  }
});
